@import '../../../../shared/styles/colors.scss';
.home-top-screen-container{
    padding-top: 5px;
    background: white;
    margin: 30px;
    border-radius: 15px;

    .icons-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E6E6E6;
        h3{
           color:  #AD3E06;
           font-size: 16px;
           font-weight: 500;
           padding-left: 20px;
        }
        .hide-row{
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 155px;
            margin-right: 20px;
            .ant-switch{
                margin-left: auto;
                height: 30px;
                border: none;
                .ant-switch-handle{
                    height: 25px;
                }
            }
            .active{
                background-color: #FF6F33;
            }
            .inactive{
                background-color: #e0e0e0;
            }
           
            .ant-switch.ant-switch-checked:hover{
                background-color: #FF6F33;
                color: #FF6F33;
            }
            .ant-switch-inner-checked{
                background-color: #FF6F33;
                color: #FF6F33;
            }
            .ant-switch-checked{
                background-color: #FF6F33;
            }
            .ant-switch-inner-unchecked{
                color: #e0e0e0 !important;
            }
        }
    }

    .icons-body{
        display: flex;
        .row-section{
            .row{
                height: 48px;
                width: 125px;
                padding-left: 20px;
                font-size: 16px;
                font-weight: 500;
                color: #393B3D;
                align-content: center;
                cursor: pointer;
            }

            .active{
                font-weight: 700;
                color: #AD3E06;
                border-right: 2px solid #AD3E06;
                background-color: #FFF0E9;
            }

            .add-new-row{
                display: flex;
                margin: auto;
                margin-top: 20px;
                margin-left: 16px;
                width: 125px;
                cursor: pointer;
                span{
                    margin-left: 10px;
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }

        .row-body{
            width: 100%;
            background-color: #E6EAEA;
            padding: 16px;
            .row-title{
                background-color: #fff !important;
            }
            .row-icon{
                margin-bottom: 15px;
            }
            .hide-row{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .hide-label{
                    margin-right: 20px;
                }
                .ant-switch{
                    height: 30px;
                    border: none;
                    .ant-switch-handle{
                        height: 25px;
                    }
                }
                .active{
                    background-color: #FF6F33;
                }
                .inactive{
                    background-color: #e0e0e0;
                }
               
                .ant-switch.ant-switch-checked:hover{
                    background-color: #FF6F33;
                    color: #FF6F33;
                }
                .ant-switch-inner-checked{
                    background-color: #FF6F33;
                    color: #FF6F33;
                }
                .ant-switch-checked{
                    background-color: #FF6F33;
                }
                .ant-switch-inner-unchecked{
                    color: #e0e0e0 !important;
                }
            }
        }
    }

    &__wrapper {
        background: $white-000;
        padding: 10px;
        padding-left: 20px;
        border-radius: 16px;

        h1 {
            font-size: 16px;
            font-weight: 500;
            color: $orange-700;
            margin: 0;
            margin-bottom: 10px;
        }

        .header{
            display: flex;
            justify-content: space-between;
        }

        .section-order{
            display: flex;
            margin-left: auto;
            width: 240px;
            justify-content: space-between;
            height: 40px;
            .del-item{
                margin-top: 15px;
            }
            .ant-select{
                width: 80px;
            }
        }

        .img-icon{
            height: 165px;
        }
    }

    .hm-item-error{
        color: #ff4d4f;
    }

    .item-invalid{
        background-color: $error-color !important;
    }

    .row-warning{
        display: flex;
        .info{
            margin-right: 5px;
            cursor: pointer;
        }
    }

    .action-btn-wrapper {
        display: flex;
        margin-left: auto;

        .updated-date-wrapper {
            span {
                font-weight: 600;
                font-size: 17px;
                color: #393B3D;
            }

            .orange {
                color: #AD3E06 !important;
                margin-left: 8px !important;
            }
        }

        .btn-promo-outer{
            width: 100%;
            justify-content: space-between;
            display: flex;
            button{
                margin-left: auto;
            }
        }
    }

    .add-icon{
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;

        label{
            cursor: pointer;
        }
    }

    .horizontal-container{
        display: grid;
        gap: 25px;
    }
  
    .home-top-screen-section{
        display: flex;
        align-items: center;
        height: 165px;
        gap: 150px;
        margin-top: 0px;
        padding-bottom: 0px;
        .rv-dropdown {
            background-color: $grey-100;
            border-radius: 16px;
            width: 100% !important;
    
            .ant-select-selector {
                border: none;
                height: 48px;
                background-color: transparent;
                align-items: center;
            }
            .ant-select-selection-item,.ant-select-selection-placeholder{
                font-size: 17px;
            }
            .ant-select-selection-placeholder{
                color: $grey-400;
            }
        }

        .home-icon-item{
            margin-top: 10px;
        }
    }

    .section{
        border-bottom: 1px solid #e0e0e0;
        font-size: 16px !important;
        font-weight: 700;
        padding-left: 0px;
        margin-top: 10px;
        margin-top: 10px;
        padding-bottom: 10px;
        .ant-form-item{
            margin-bottom: 10px;
            margin-top: 10px;
        }
        label{
            margin-top: 10px;
        }
        .check-label-promo{
            padding-top: 10px;
            display: flex;
        }
    }
    .no-border{
        border-bottom: none;
        padding: 0px;
    }

    .last-section{
        .ant-btn { margin-top: 10px;}
        .drp-type-val{
            width: 300px;
        }
    }

    .name-section{
        width: 578px;
        .ant-form-item{
            margin-bottom: 0px;
        }
        .welcome-meesage-info{
            display: block;
            margin-top: 5px;
            margin-left: 540px;
        }
    }

    .label-links{
        margin-top: 15px;
    }
}

.home-screen-item-container{
    
    .search-section{
        display: flex;
        align-items: center;
        gap: 25px;
    }
    .drp-section{
        height: 100px;
    }
    .rv-dropdown {
        border-radius: 16px;
        width: 100% !important;

        .ant-select-selector {
            height: 44px;
            background-color: transparent;
            align-items: center;
            width: 395px;
        }
        .ant-select-selection-item, .ant-select-selection-placeholder{
            font-size: 17px;
        }
        .ant-select-selection-placeholder{
            color: $grey-400;
        }
    }
.home-screen-item-container__body__search {
    .searchbox-container {
      width: 100% !important;
    }
    margin-bottom: 10px;
    .search-promo-text {
        margin-bottom: 15px;
        height: 61px;
        margin-top: 20px;
    }
  }

  .action-btn-wrapper {
    display: flex;
    margin-left: auto;
    gap: 10px;
    width: 175px;
    }
}

